//Theme css
@import 'theme';

//Custom css
@import 'custom';

//datetimepicker
@import'datetimepicker';

//datatable
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';

//calendar
@import 'calendar';

@import 'select2';


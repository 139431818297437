.fc-day.fc-sat.fc-past {
    background-color: blanchedalmond;
}

.fc-day.fc-sun.fc-past {
    background-color: blanchedalmond;
}

.fc-title {
    color: white;
}

#dataTable_filter,
.dataTables_filter {
    float: right;
}

.start-stop-btn {
    font-size: 200px;
    margin-left: 29%;
    color: white;
}


.mr-2 {
    margin-right: 2px;
}

.label-danger {
    color: #dc3545;
}

.error {
    color: #dc3545;
}


#progress {
    display: none;
    color: green;
}

.middle {
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-top: 70px;
    /* Added */
}

.invalid-feedback-login {
    display: block !important;
    color: indianred;
}

.login-background {
    background-color: whitesmoke
}

.cube-color {
    color: #ff6219; 
}

.br-1 {
    border-radius: 1rem;
}

.br-all {
    border-radius: 1rem 0 0 1rem;
}

.ls-1{
    letter-spacing: 1px;
}

.fc-event-time {
    color: white !important;
}

.fc-event-title {
    color: white !important;
}

.card-overflow {
    overflow-y:scroll;
}

.bg-awhite {
    background-color: antiquewhite;
}

.alert-z-index {
    z-index: 11;
}

.notify-inline-block {
    display: inline-block;
}
.mt-100 {
    margin-top:100px;
}

.mce-menubar {
    display: none !important;
}
.wrem50 {
    width: 50rem;
}
.hrem50 {
    height: 50rem;
}
.mt-20 {
    margin-top: 20%;
}
.mr-4{
    margin-right: 4px;
}
.mt-6 {
    margin-top: 6%;
}

.form-group {
    padding-top: 8px !important;
}
label {
    padding-bottom: 8px !important;
}
#message {
    top: 3px !important;
    position: relative; 
}
.modal-footer {
   border-top: 0px solid #dee2e6 !important; 
}